import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ContentContainer from "../../../components/ContentContainer";
import Badge from "../../../components/Patients/Badge";
import api from "../../../services/api";
import { notifyError } from "../../../utils";
import { CircularProgress } from "@material-ui/core";

import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import { getHospitalId } from "../../../services/auth";
import TimeAgo from "timeago-react";

const models = [
  {
    name: "hemograma",
    field: "cbc",
  },
  {
    name: "sintomas",
    field: "anamnese",
  },
];

export default function PatientsById(props) {
  const id = props.match.params.id;
  const [patient, setPatient] = useState({});
  const [attendances, setAttendances] = useState([]);
  const [loadingUser, setLoadingUser] = useState(true);
  const [loadingAttendances, setLoadingAttendances] = useState(true);
  const [error, setError] = useState(false);
  const [render, setRender] = useState(0);
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get(`/users/${id}`);
        setPatient(response.data);
      } catch (error) {
        notifyError(error);
        setError(true);
      } finally {
        setLoadingUser(false);
      }
    }
    fetchData();
  }, [id, render]);

  useEffect(() => {
    async function fetchData() {
      try {
        const hospitalId = getHospitalId();
        const response = await api.get(
          `/institutions/${hospitalId}/attendances?patient_id=${id}`
        );
        setAttendances(response.data);
      } catch (error) {
        notifyError(error);
        setError(true);
      } finally {
        setLoadingAttendances(false);
      }
    }
    fetchData();
  }, [id, render]);

  function handleReload() {
    setLoadingUser(true);
    setLoadingAttendances(true);
    setError(false);
    setRender(render + 1);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const hospitalId = getHospitalId();
      const response = await api.post(
        `/institutions/${hospitalId}/attendances`,
        { patient_id: id }
      );
      history.push(`/attendances/${response.data.id}`);
    } catch (error) {
      notifyError(error);
    }
  }

  function CustomizedTimeline() {
    return (
      <Timeline align="alternate">
        {attendances.map((attendance, index) => (
          <TimelineItem key={index}>
            <TimelineOppositeContent>
              <Link key={attendance.id} to={`/attendances/${attendance.id}`}>
                <TimeAgo
                  className="nowrap"
                  datetime={attendance.timestamp + "Z"}
                />
              </Link>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <Link key={attendance.id} to={`/attendances/${attendance.id}`}>
                <TimelineDot>
                  <LocalHospitalIcon />
                </TimelineDot>
              </Link>
              {index !== attendances.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Link key={attendance.id} to={`/attendances/${attendance.id}`}>
                <div className="content-box padding-small">
                  <p>
                    Atendido por{" "}
                    {attendance.caregiver.name
                      ? attendance.caregiver.name
                      : "Anonimo"}
                  </p>
                  {models.some((model) => attendance[model]) && (
                    <p>
                      Foi testado para COVID-19 utilizando
                      {models.map(
                        (model, index2) =>
                          attendance[model.field] && (
                            <span key={index2}>
                              {" "}
                              {model.name}
                              {index2 !== models.length - 1 && ", "}
                            </span>
                          )
                      )}
                      .
                    </p>
                  )}
                </div>
              </Link>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    );
  }

  return (
    <ContentContainer>
      {loadingUser || loadingAttendances ? (
        <div className="flex-row flex-crossaxis-center padding-large">
          <CircularProgress />
        </div>
      ) : error ? (
        <div className="flex-row flex-crossaxis-center flex-axis-center padding-large">
          <div className="margin-sides-xsmall">Problem loading, try to </div>
          <button className="button-tertiary" onClick={handleReload}>
            Reload
          </button>
        </div>
      ) : (
        <>
          <Badge patient={patient} />
          <button className="button-tertiary" onClick={handleSubmit}>
            Novo atendimento
          </button>
          <CustomizedTimeline />
        </>
      )}
    </ContentContainer>
  );
}

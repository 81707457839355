import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import api, { logout } from "../../services/api";
import { getUserInfo, setHospitalId } from "../../services/auth";
import { notifyError } from "../../utils";

export default function InstitutionSelection() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const loggedUser = getUserInfo();
  const [institutions, setInstitutions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get(`users/${loggedUser.id}/institutions`);
        setInstitutions(response.data);
        if (response.data.length === 1) {
          setHospitalId(response.data[0].id);
          history.push("/attendances");
        } else {
          setLoading(false);
        }
      } catch (error) {
        notifyError(error);
      }
    }
    fetchData();
  }, [loggedUser.id, history]);

  function handleSubmit() {}

  return (
    <React.Fragment>
      {loading ? (
        <div className="flex-row flex-crossaxis-center padding-large">
          <CircularProgress />
        </div>
      ) : (
        <div className="margin-top-xlarge flex-column flex-axis-center">
          {institutions.length === 0 ? (
            <>
              <h1 className="color-tertiary">
                Você nao está vinculado a nenhuma instituição
              </h1>
              <button
                onClick={logout}
                className="margin-top-medium button-tertiary"
                type="submit"
              >
                Sair
              </button>
            </>
          ) : (
            <>
              <h1 className="color-tertiary">Hospital</h1>
              <form
                className="padding-medium flex-column content-box margin-vertical-small"
                onSubmit={handleSubmit}
              >
                <button
                  className="margin-top-medium button-tertiary"
                  type="submit"
                >
                  Continuar
                </button>
              </form>
            </>
          )}
        </div>
      )}
    </React.Fragment>
  );
}

import { CircularProgress } from "@material-ui/core";
import React from "react";

export default function LoadingCheck({
  children,
  loading,
  setLoading,
  error,
  setError,
  setRender,
}) {
  function handleReload() {
    setLoading(true);
    setError(false);
    setRender(Math.floor(Math.random() * 1000));
  }

  return loading ? (
    <div className="flex-row flex-crossaxis-center padding-large">
      <CircularProgress />
    </div>
  ) : error ? (
    <div className="flex-row flex-crossaxis-center flex-axis-center padding-large">
      <div className="margin-sides-xsmall">Problem loading, try to </div>
      <button className="button-tertiary" onClick={handleReload}>
        Reload
      </button>
    </div>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
}

import React from "react";
import { Redirect, Route } from "react-router-dom";

import { getHospitalId, userIsAuthenticated } from "../../services/auth";

const PrivateRoute = ({
  component: Component,
  requireHospital = true,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        userIsAuthenticated() && (requireHospital ? getHospitalId() : true) ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

export default PrivateRoute;

import React from "react";

import "./styles.css";

import marker from "../../assets/marker.svg";

export default function AnalyteBar({ ai }) {
  const scaleSize = ai.upper_limit - ai.lower_limit;
  let markerPosition = (ai.measurement.value - ai.lower_limit) / (scaleSize) * 100;

  if (markerPosition > 100) {
    markerPosition = 100
  } else if (markerPosition < 0) {
    markerPosition = 0
  }

  const nodes = [
    { out: true, percentage: 100 * (ai.lower_normal - ai.lower_limit) / (scaleSize) },
    { out: false, percentage: 100 * (ai.upper_normal - ai.lower_normal) / (scaleSize) },
    { out: true, percentage: 100 * (ai.upper_limit - ai.upper_normal) / (scaleSize) },
  ];

  return (
    <div className="flex-row size-analyte-bar">
      <div className="flex-row width-100p height-100p">
        {nodes.map((node, index) => (
          <div
            key={index}
            className={`text-center ${node.out ? "bar-negative" : "bar-positive"
              } ${index === 0 ? "radius-left" : ""} ${index === nodes.length - 1 ? "radius-right" : ""
              }`}
            style={{ width: `${node.percentage}%` }}
          >
            <span
              className="color-secondary"
              style={{ position: "relative", zIndex: "2" }}
            >
              {node.label}
            </span>
          </div>
        ))}
      </div>
      <div style={{ marginLeft: `calc(-5px - ${100 - markerPosition}%)` }}>
        <img src={marker} alt="" height="24px" />
      </div>
      <div style={{ marginLeft: `calc(-5px + ${100 - markerPosition}%)` }} />
      <div
        style={{
          borderLeft: `3px dotted ${ai.decision_threshold ? "black" : "transparent"}`,
          height: "24px",
          marginLeft: `calc(-${100 - ai.decision_threshold}%)`,
        }}
      />
      <div
        style={{ marginLeft: `calc(-1px + ${100 - ai.decision_threshold}%)` }}
      />
    </div>
  );
}

import React, { createContext } from "react";

export const HospitalContext = createContext();

const HospitalProvider = ({ children }) => {
  return (
    <HospitalContext.Provider value={{}}>{children}</HospitalContext.Provider>
  );
};

export default HospitalProvider;

import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import ContentContainer from "../../../../components/ContentContainer";

export default function AttendancePatientNotFound(props) {
  const history = useHistory();
  const searchString =
    props.location.state && props.location.state.searchString;
  if (!searchString) {
    history.push("/attendances/new");
  }

  return (
    <ContentContainer>
      <h1 className="text-center">Novo atendimento</h1>
      <p>
        Busca por <span className="bold">{searchString}</span> nao retornou
        nenhum resultado
      </p>
      <Link
        to={{
          pathname: "/attendances/new",
          state: { searchString: searchString },
        }}
      >
        <button className="button-tertiary">Buscar novamente</button>
      </Link>
      <Link
        to={{
          pathname: "/patients/new/personalinfo",
          state: { searchString: searchString },
        }}
        className="margin-left-xsmall"
      >
        <button className="button-tertiary">Novo paciente</button>
      </Link>
    </ContentContainer>
  );
}

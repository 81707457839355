import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import queryString from "query-string";
import TimeAgo from "timeago-react";

import api from "../../services/api";
import { notifyError } from "../../utils";

export default function ListTable({ baseUrl, itemsUrl, fields, showTimestamp = false }) {
  const fetchUrl = baseUrl + itemsUrl;
  const location = useLocation();
  const history = useHistory();
  const [items, setItems] = useState([]);
  const [filter, setFilter] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [render, setRender] = useState(0);
  const [page, setPage] = useState();
  const [totalPages, setTotalPages] = useState();
  const [lastPage, setLastPage] = useState();
  const [pageSize, setPageSize] = useState(
    queryString.parse(location.search).page_size || 10
  );

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get(fetchUrl + location.search);
        const pagination = JSON.parse(response.headers["x-pagination"]);
        setPage(pagination.page);
        setTotalPages(pagination.total_pages);
        setLastPage(pagination.last_page);
        setItems(response.data);
        setFilteredItems(response.data);
        setError(false);
      } catch (error) {
        notifyError(error);
        setError(true);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [fetchUrl, render, location]);

  function handleSearchSubmit(e) {
    e.preventDefault();
    let newParsedQueries = queryString.parse(location.search);
    newParsedQueries["q"] = filter;
    history.push(
      location.pathname + "?" + queryString.stringify(newParsedQueries)
    );
  }

  function handlePreviousPage() {
    let newParsedQueries = queryString.parse(location.search);
    newParsedQueries["page"] = page - 1;
    history.push(
      location.pathname + "?" + queryString.stringify(newParsedQueries)
    );
  }

  function handleNextPage() {
    let newParsedQueries = queryString.parse(location.search);
    newParsedQueries["page"] = page + 1;
    history.push(
      location.pathname + "?" + queryString.stringify(newParsedQueries)
    );
  }

  function handleChangePageSize(e) {
    setPageSize(e.target.value);
    let newParsedQueries = queryString.parse(location.search);
    newParsedQueries["page_size"] = e.target.value;
    newParsedQueries["page"] = 1;
    history.push(
      location.pathname + "?" + queryString.stringify(newParsedQueries)
    );
  }

  function handleReload() {
    setRender(render + 1);
    setLoading(true);
  }

  return (
    <div className="content-box margin-xxsmall">
      {loading ? (
        <div className="flex-row flex-crossaxis-center padding-large">
          <CircularProgress />
        </div>
      ) : error ? (
        <div className="flex-row flex-crossaxis-center flex-axis-center padding-large">
          <div className="margin-sides-xsmall">Problem loading, try to </div>
          <button className="button-tertiary" onClick={handleReload}>
            Reload
          </button>
        </div>
      ) : (
        <>
          <div className="flex-row padding-small">
            <form onSubmit={(e) => handleSearchSubmit(e)}>
              <TextField
                variant="outlined"
                label="Buscar"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </div>

          <div className="flex-row flex-axis-center padding-small">
            <span>Rows</span>
            <div className="padding-left-small">
              <select
                name="pageSize"
                id="pageSize"
                onChange={(e) => handleChangePageSize(e)}
                value={pageSize}
              >
                <option value={2}>2</option>
                <option value={10}>10</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <span className="padding-sides-small">
              {page ? page : 0} of {totalPages}
            </span>
            {page > 1 ? (
              <button onClick={handlePreviousPage} className="flex">
                <ArrowLeft />
              </button>
            ) : (
              <ArrowLeft className="icon-deactivated" />
            )}
            {page < lastPage ? (
              <button onClick={handleNextPage} className="flex">
                <ArrowRight />
              </button>
            ) : (
              <ArrowRight className="icon-deactivated" />
            )}
          </div>

          {filteredItems.length === 0 && (
            <div className="flex-row padding-small">
              <span className="flex-row flex-axis-center">
                No results found
              </span>
            </div>
          )}

          {items.length > 0 && (
            <div className="flex-row flex-space-between padding-small bold">
              {fields.map((field, index) => (
                <div key={index}>{field.name}</div>
              ))}
              {
                showTimestamp &&
                <div>Data</div>
              }
            </div>
          )}

          {filteredItems.map((item) => (
            <div
              key={item.id}
              className="flex-row box-horizontal background-hover padding-sides-small"
            >
              <Link
                className="flex-row flex-space-between padding-vertical-small width-100p"
                to={`${itemsUrl}/${item.id}`}
              >
                {fields.map((field, index) => (
                  <div
                    key={index}
                    className={`ellipsis-1 ${field.className}`}
                  >
                    {field.path.split(".").reduce((a, b) => a[b], item)}
                  </div>
                ))}
                {
                  showTimestamp &&
                  <div>
                    <TimeAgo className="nowrap" datetime={item.timestamp + "Z"} />
                  </div>
                }
              </Link>
            </div>
          ))}
        </>
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import ContentContainer from "../../../../components/ContentContainer";
import LoadingCheck from "../../../../components/LoadingCheck";

export default function NewPrediction(props) {
  const id = props.match.params.id;
  const [availablePredictions, setAvailablePredictions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [render, setRender] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        setAvailablePredictions([
          {
            id: 1,
            name: "Covid sintomas",
          },
          {
            id: 2,
            name: "Covid Raiox",
          },
        ]);
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [id, render]);

  return (
    <ContentContainer>
      <LoadingCheck
        loading={loading}
        setLoading={setLoading}
        error={error}
        setError={setError}
        setRender={setRender}
      >
        <h1>Predições atualmente disponíveis</h1>
        <div className="flex-wrap margin-bottom-small">
          {availablePredictions.map((prediction) => (
            <span
              key={prediction.id}
              className="button2-warning2-small margin-xxsmall"
            >
              {prediction.name}
            </span>
          ))}
        </div>
      </LoadingCheck>
    </ContentContainer>
  );
}

import React, { useEffect, useState } from "react";
import ContentContainer from "../../../../components/ContentContainer";
import TextField from "@material-ui/core/TextField";
import Badge from "../../../../components/Patients/Badge";
import { formStyles, notifyError } from "../../../../utils";
import api from "../../../../services/api";
import LoadingCheck from "../../../../components/LoadingCheck";
import { getHospitalId } from "../../../../services/auth";
import { useHistory } from "react-router";

export default function AttendancesNewById(props) {
  const id = props.match.params.id;
  const [patient, setPatient] = useState({});
  const [attendance, setAttendance] = useState({
    bodymeasurements: {},
    patient_id: id,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [render, setRender] = useState(0);
  const formClasses = formStyles();
  const hospitalId = getHospitalId();
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get(`/users/${id}`);
        setPatient(response.data);
      } catch (error) {
        notifyError(error);
        setError(true);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [id, render]);

  function handleEditBodyMeasurements(field, value) {
    let newAttendance = { ...attendance };
    newAttendance["bodymeasurements"][field] = value;
    setAttendance(newAttendance);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const response = await api.post(
        `/institutions/${hospitalId}/attendances`,
        attendance
      );
      history.push(`/attendances/${response.data.id}`);
    } catch (error) {
      notifyError(error);
    }
  }

  return (
    <ContentContainer>
      <LoadingCheck
        loading={loading}
        setLoading={setLoading}
        error={error}
        setError={setError}
        setRender={setRender}
      >
        <Badge patient={patient} />
        <form onSubmit={handleSubmit}>
          <div>
            <TextField
              className={formClasses.fields}
              required
              type="number"
              label="Altura (m)"
              inputProps={{
                step: 0.01,
                min: 0.0,
              }}
              value={attendance.bodymeasurements.height || ""}
              onChange={(e) =>
                handleEditBodyMeasurements("height", e.target.value)
              }
            />
            <TextField
              className={formClasses.fields}
              required
              type="number"
              label="Peso (kg)"
              inputProps={{
                min: 0,
              }}
              value={attendance.bodymeasurements.weight || ""}
              onChange={(e) =>
                handleEditBodyMeasurements("weight", e.target.value)
              }
            />
            <TextField
              className={formClasses.fields}
              required
              type="number"
              label="Temperatura (°C)"
              inputProps={{
                min: 32,
                max: 43,
                step: 0.1,
              }}
              value={attendance.bodymeasurements.temperature || ""}
              onChange={(e) =>
                handleEditBodyMeasurements("temperature", e.target.value)
              }
            />
          </div>

          <button className="margin-top-small button-tertiary" type="submit">
            Criar
          </button>
        </form>

        {/* {                
                influenza: influenza,
                parainfluenza: parainfluenza,
                h1n1: h1n1,
                chlamydophila: chlamydophila,
                rhinovirus: rhinovirus,
                virussincicial: virussincicial,
                outroscov: outroscov,
                outrasinfeccoes: outrasinfeccoes,
                pcr: pcr,
                cbc: 
                    {
                        hemacias: hemacias,
                        hematocritos: hematocrito,
                        hemoglobinas: hemoglobinas,
                        vcm: vcm,
                        hcm: hcm,
                        chcm: chcm,
                        rdw: rdw,
                        leucocitos: leucocitos,
                        mielocitos: mielocitos,
                        metamielocitos: metamielocitos,
                        bastonetes: bastonetes,
                        segmentados: segmentados,
                        neutrofilos_totais: neutrofilos,
                        eosinofilos: eosinofilos,
                        basofilos: basofilos,
                        linfocitos: linfocitos,
                        monocitos: monocitos,
                        plaquetas: plaquetas,
                        vmp: vmp,
                        promielocitos: promielocitos,
                        plasmocitos: plasmocitos
                    }
            } */}
      </LoadingCheck>
    </ContentContainer>
  );
}

import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import ExpandMore from "@material-ui/icons/ExpandMore";
import Menu from "@material-ui/core/Menu";

import "./styles.css";
import logo from "../../assets/logo-vector.svg";
import Ellipsis from "../Ellipsis";
import { getUserInfo, userIsAuthenticated } from "../../services/auth";
import MenuIcon from "@material-ui/icons/Menu";
import { Drawer } from "@material-ui/core";
import SideMenu from "../SideMenu";

export default function Header(props) {
  const history = useHistory();
  const loggedUser = getUserInfo();
  const [openUserMenu, setOpenUserMenu] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const userMenuRef = useRef(null);

  function handleLogout() {
    localStorage.clear();
    history.push("/");
  }

  return (
    <header>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <SideMenu marginTop={true} />
      </Drawer>
      <nav className="flex-row flex-axis-center flex-space-between padding-small shadow height-header">
        <div className="flex-row flex-axis-center">
          <button
            id="small-show"
            className="flex-row margin-right-small"
            onClick={() => setDrawerOpen(true)}
          >
            <MenuIcon />
          </button>
          <Link
            to="/attendances"
            className="flex-row flex-axis-center color-tertiary size-xlarge bold"
          >
            <img src={logo} height="30px" width="30px" alt="Logo" />
            &nbsp;LabLift
          </Link>
        </div>
        <div className="flex-row flex-axis-center">
          {userIsAuthenticated() && (
            <>
              <button
                className="flex-row"
                ref={userMenuRef}
                onClick={() => setOpenUserMenu(!openUserMenu)}
              >
                <ExpandMore />
              </button>
              <Menu
                anchorEl={userMenuRef.current}
                keepMounted
                open={openUserMenu}
                onClose={() => setOpenUserMenu(!openUserMenu)}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <div className="flex-column flex-axis-center padding-large">
                  <Link
                    className="margin-top-small"
                    to={`/users/${loggedUser.username}`}
                  >
                    <Ellipsis n_lines={1}>
                      <h1>{loggedUser.name}</h1>
                    </Ellipsis>
                  </Link>
                  <button
                    onClick={handleLogout}
                    className="margin-top-small padding-small box background-hover width-100p"
                  >
                    Logout
                  </button>
                </div>
              </Menu>
            </>
          )}
        </div>
      </nav>
    </header>
  );
}

import React from "react";

import { Link } from "react-router-dom";
import ContentContainer from "../../components/ContentContainer";
import ListTable from "../../components/ListTable";
import { getHospitalId } from "../../services/auth";

export default function Attendances() {
  const hospitalId = getHospitalId();

  return (
    <ContentContainer>
      <h1 className="text-center">Atendimentos</h1>
      <Link to="/attendances/new">
        <button className="button-tertiary margin-vertical-medium">
          Iniciar atendimento
        </button>
      </Link>
      <ListTable
        showTimestamp
        baseUrl={`/institutions/${hospitalId}`}
        itemsUrl={"/attendances"}
        fields={[
          { name: "Paciente", path: "patient.name" },
          { name: "Responsável", path: "caregiver.name" },
        ]}
      />
    </ContentContainer>
  );
}

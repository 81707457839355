import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import ContentContainer from "../../../components/ContentContainer";
import LoadingCheck from "../../../components/LoadingCheck";
import Badge from "../../../components/Patients/Badge";
import CovidSymptomsAccordion from "../../../components/Attendances/CovidSymptomsAccordion";
import api from "../../../services/api";
// import GetAppIcon from "@material-ui/icons/GetApp";
// import EmailIcon from "@material-ui/icons/Email";
// import PrintIcon from "@material-ui/icons/Print";
// import HelpIcon from "@material-ui/icons/Help";
import { formStyles, notifyError } from "../../../utils";

import "./styles.css";
import CovidCbcAccordion from "../../../components/Attendances/CovidCbcAccordion";
import axios from "axios";
import AnalyteDesc from "../../../components/AnalyteDesc";

export default function AttendancesById(props) {
  const id = props.match.params.id;
  const [attendance, setAttendance] = useState({});
  const [availablePredictions, setAvailablePredictions] = useState([]);
  const [predictions, setPredictions] = useState([]);
  const [analytes, setAnalytes] = useState({});
  const [missingAnalytes, setMissingAnalytes] = useState([]);
  const [usingAnalytes, setUsingAnalytes] = useState([]);
  const [newPredictionActive, setNewPredictionActive] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingFields, setLoadingFields] = useState(false);
  const [predictionRunning, setPredictionRunning] = useState(false);
  const [error, setError] = useState(false);
  const [render, setRender] = useState(0);

  const formClasses = formStyles();

  const ModelsComponents = {
    covidbloodv1: CovidCbcAccordion,
    covidxrayv1: CovidCbcAccordion,
    covidsymptomsv1: CovidSymptomsAccordion,
  };

  const Model = ({ id, ...rest }) => {
    const Component = ModelsComponents[id];
    return <Component {...rest} />;
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const [response, response2, response3] = await axios.all([
          api.get(`/attendances/${id}`),
          api.get(`/attendances/${id}/availablemodels`),
          api.get(`/attendances/${id}/predictions`),
        ]);
        setAttendance(response.data);
        setAvailablePredictions(response2.data);
        setPredictions(response3.data);
      } catch (error) {
        notifyError(error);
        setError(true);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [id, render]);

  async function handleNewPredictionClick(prediction) {
    setAnalytes({});
    if (newPredictionActive === prediction) {
      setNewPredictionActive();
      setMissingAnalytes({});
      setUsingAnalytes({});
      return;
    }
    setNewPredictionActive(prediction);
    setLoadingFields(true);
    try {
      const [response, response2] = await axios.all([
        api.get(`models/${prediction.id}/missing?attendance_id=${id}`),
        api.get(`models/${prediction.id}/using?attendance_id=${id}`),
      ]);
      setMissingAnalytes(response.data["analytes"]);
      setUsingAnalytes(response2.data["analytes"]);
    } catch (error) {
      notifyError(error);
      setMissingAnalytes({});
      setUsingAnalytes({});
    } finally {
      setLoadingFields(false);
    }
  }

  async function handleSubmitAnalytes(e) {
    e.preventDefault();
    const data = {
      analytes: analytes,
    };
    try {
      await api.put(`attendances/${id}`, data);
      setAnalytes({});
      setMissingAnalytes([]);
      setUsingAnalytes([]);
    } catch (error) {
      notifyError(error);
    }
    handleNewPredictionClick(newPredictionActive);
  }

  function handleEditAnalyte(analyte, value) {
    let newAnalytes = { ...analytes };
    newAnalytes[analyte] = {};
    newAnalytes[analyte]["value"] = value;
    setAnalytes(newAnalytes);
  }

  async function handlePredictModel(model) {
    setPredictionRunning(true);
    try {
      await api.post(`models/${model.id}/predict/${attendance.id}`);
      setRender(render + 1);
    } catch (error) {
      notifyError(error);
    } finally {
      setPredictionRunning(false);
    }
  }

  return (
    <ContentContainer>
      <LoadingCheck
        loading={loading}
        setLoading={setLoading}
        error={error}
        setError={setError}
        setRender={setRender}
      >
        {/* <div className="flex-row flex-crossaxis-end">
          <GetAppIcon />
          <EmailIcon className="margin-left-xsmall" />
          <PrintIcon className="margin-left-xsmall" />
          <HelpIcon className="margin-left-xsmall" />
        </div> */}
        <Badge patient={attendance.patient} attendance={attendance} />
        {
          availablePredictions.length > 0 &&
          <div className="box padding-small margin-bottom-small">
            <p className="size-larger bold">Predições disponíveis</p>
            <div className="flex-wrap margin-bottom-small">
              {availablePredictions.map((prediction) => (
                <button
                  key={prediction.id}
                  className="margin-xxsmall"
                  onClick={() => handleNewPredictionClick(prediction)}
                >
                  <span
                    className={`${newPredictionActive?.id === prediction.id
                      ? "button2-warning"
                      : "button2-warning2"
                      }`}
                  >
                    {prediction.name_ptbr}
                  </span>
                </button>
              ))}
            </div>

            {loadingFields ? (
              <div className="flex-row flex-crossaxis-center padding-large">
                <CircularProgress />
              </div>
            ) : (
              <>
                {missingAnalytes.length > 0 && (
                  <>
                    <p className="bold">
                      Informações necessárias para utilizar o módulo{" "}
                      <span className="color-error">
                        {newPredictionActive.name_ptbr}
                      </span>
                      .
                    </p>
                    <form autoComplete="off" onSubmit={handleSubmitAnalytes}>
                      <div className="flex-wrap flex-axis-center margin-bottom-small">
                        {missingAnalytes.map((item) => (
                          <div key={item.id}>
                            {item.type === "text" && (
                              <TextField
                                className={formClasses.fields}
                                required
                                label={item.name_ptbr}
                                inputProps={{
                                  minLength: item.min,
                                  maxLength: item.max,
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {item.unit}
                                    </InputAdornment>
                                  ),
                                }}
                                value={
                                  (analytes[item.loinc_analyte_code] &&
                                    analytes[item.loinc_analyte_code]["value"]) ||
                                  ""
                                }
                                onChange={(e) =>
                                  handleEditAnalyte(
                                    item.loinc_analyte_code,
                                    e.target.value
                                  )
                                }
                              />
                            )}
                            {item.type === "select" && (
                              <FormControl
                                required
                                className={formClasses.fields}
                              >
                                <InputLabel id={"item.name"}>
                                  {item.name}
                                </InputLabel>
                                <Select
                                  labelId={"item.name"}
                                  value={
                                    (analytes[item.loinc_analyte_code] &&
                                      analytes[item.loinc_analyte_code][
                                      "value"
                                      ]) ||
                                    item.fields[0].tag
                                  }
                                  onChange={(e) =>
                                    handleEditAnalyte(
                                      item.loinc_analyte_code,
                                      e.target.value
                                    )
                                  }
                                >
                                  {item.fields.map((option) => (
                                    <MenuItem key={option.tag} value={option.tag}>
                                      {option.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                            {item.type === "date" && (
                              <TextField
                                className={formClasses.fields}
                                required
                                type="date"
                                label={item.name_ptbr}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={
                                  (analytes[item.loinc_analyte_code] &&
                                    analytes[item.loinc_analyte_code]["value"]) ||
                                  ""
                                }
                                onChange={(e) =>
                                  handleEditAnalyte(
                                    item.loinc_analyte_code,
                                    e.target.value
                                  )
                                }
                              />
                            )}
                            {(item.type === "number") | !item.type && (
                              <TextField
                                className={formClasses.fields}
                                required
                                type="number"
                                label={item.name_ptbr}
                                inputProps={{
                                  min: item.min,
                                  max: item.max,
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {item.unit}
                                    </InputAdornment>
                                  ),
                                }}
                                value={
                                  (analytes[item.loinc_analyte_code] &&
                                    analytes[item.loinc_analyte_code]["value"]) ||
                                  ""
                                }
                                onChange={(e) =>
                                  handleEditAnalyte(
                                    item.loinc_analyte_code,
                                    e.target.value
                                  )
                                }
                              />
                            )}
                          </div>
                        ))}
                      </div>
                      <button
                        className="button-tertiary margin-bottom-small"
                        type="submit"
                      >
                        Enviar
                      </button>
                    </form>
                  </>
                )}

                {usingAnalytes.length > 0 && (
                  <div>
                    <p className="bold">
                      Informações utilizadas pelo módulo{" "}
                      <span className="color-error">
                        {newPredictionActive.name_ptbr}
                      </span>
                      .
                    </p>

                    {usingAnalytes.map((analyte) => (
                      <AnalyteDesc key={analyte.id} analyte={analyte} />
                    ))}
                  </div>
                )}
                {newPredictionActive && missingAnalytes.length === 0 && (
                  predictionRunning ?
                    <CircularProgress /> :
                    <button
                      className="button-tertiary"
                      onClick={() => handlePredictModel(newPredictionActive)}
                    >
                      Submeter para predição
                    </button>
                )}
              </>
            )}
          </div>
        }

        {predictions.length > 0 ? (
          predictions.map((prediction) => (
            <div key={prediction.id}>
              <div className="content-box margin-bottom-small">
                {/* <span>model id: {prediction.model_id}</span> */}

                {prediction.model_id && (
                  <Model
                    id={prediction.model_id}
                    attendance={attendance}
                    prediction={prediction}
                  />
                )}

                {/* <CovidSymptomsAccordion attendance={attendance} prediction={attendance.predictions[0]} /> */}
              </div>
              {/* <div className="content-box margin-bottom-small">
                  <CovidCbcAccordion />
                </div> */}
            </div>
          ))
        ) : (
          <div className="margin-auto box shadow padding-medium max-width-xxxxlarge background-quinary">
            <p className="text-center">Suas predições aparecerão aqui</p>
          </div>
        )}
      </LoadingCheck>
    </ContentContainer>
  );
}

import React, { useState } from "react";
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";

const cpfMask = (value) => {
  return value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

export default function Badge({
  patient,
  canHideData = true,
  linkActive = true,
  attendance = {},
}) {
  const [hideInfo, setHideInfo] = useState(false);
  const hideString = "*****";
  const descriptionFields = [
    { value: cpfMask(patient.document), unit: "", hideable: true },
    { value: patient.age, unit: "anos" },
    { value: patient.height, unit: "m" },
    { value: patient.weight, unit: "kg" },
  ];

  let descriptionString = "";
  for (let index = 0; index < descriptionFields.length; index++) {
    const element = descriptionFields[index];
    if (element.value) {
      if (element.hideable && hideInfo) {
        descriptionString += hideString;
      } else {
        descriptionString +=
          element.value + (element.unit ? " " + element.unit : "");
      }
    }
    descriptionString +=
      descriptionFields[index + 1] && descriptionFields[index + 1].value
        ? " • "
        : "";
  }

  return (
    <div className="content-box padding-small margin-bottom-medium">
      <div className="flex-wrap flex-space-between flex-axis-center">
        <p className="size-xlarge">
          {hideInfo ? (
            hideString
          ) : linkActive ? (
            <Link to={`/patients/${patient.id}`}>{patient.name}</Link>
          ) : (
            patient.name
          )}
        </p>
        {canHideData && (
          <div className="flex-row flex-axis-center">
            <span className="size-smaller">Ocultar dados sensiveis</span>
            <button
              className="flex-row margin-left-xsmall"
              onClick={() => setHideInfo(!hideInfo)}
            >
              <Switch
                color="primary"
                checked={hideInfo}
                onChange={() => setHideInfo(!hideInfo)}
              />
            </button>
          </div>
        )}
      </div>
      <p>{descriptionString}</p>
      <p>{attendance.institution?.company_name}</p>
    </div>
  );
}

import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <React.Fragment>
      <div className="margin-top-large flex-column flex-axis-center">
        <h1>
          <Link to="/" className="color-tertiary">
            LabLift App
          </Link>
        </h1>
        <p className="margin-top-small">Página não encontrada</p>
        <p className="margin-top-small">
          <Link className="color-tertiary underline" to="/">
            Ir para home
          </Link>
        </p>
      </div>
    </React.Fragment>
  );
}

import React from "react";

export default function AnalyteDesc({ analyte }) {
  return (
    <div className="flex-wrap">
      <span className="width-analyte-name margin-xxsmall">
        {analyte.details.name_ptbr}
      </span>
      <span className="width-analyte-value margin-xxsmall">
        <span className="bold">{analyte.value}</span> ({analyte.details.unit})
      </span>
    </div>
  );
}

import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Attendances from "./pages/Attendances";
import NewUser from "./pages/Users/New";
import HospitalSelection from "./pages/HospitalSelection";

import Index from "./pages/Index";
import NotFound from "./pages/NotFound";
import NewAttendance from "./pages/Attendances/New";
import PrivateRoute from "./components/PrivateRoute";
import AttendancesById from "./pages/Attendances/ById";
import AttendancesNewById from "./pages/Attendances/New/ById";
import Patients from "./pages/Patients";
import NewPatient from "./pages/Patients/New";
import PatientsById from "./pages/Patients/ById";
import AttendancePatientNotFound from "./pages/Attendances/New/NotFound";
import PatientExistent from "./pages/Patients/New/Existent";
import PatientPersonalInfo from "./pages/Patients/New/PersonalInfo";
import Teste from "./pages/Teste";
import NewPrediction from "./pages/Attendances/ById/NewPrediction";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Index} />
        <PrivateRoute
          path="/hospitalselection"
          component={HospitalSelection}
          requireHospital={false}
        />
        <PrivateRoute path="/attendances" exact component={Attendances} />
        <PrivateRoute path="/attendances/new" exact component={NewAttendance} />
        <PrivateRoute
          path="/attendances/:id"
          exact
          component={AttendancesById}
        />
        <PrivateRoute
          path="/attendances/:id/newprediction"
          exact
          component={NewPrediction}
        />
        <PrivateRoute
          path="/attendances/new/notfound"
          exact
          component={AttendancePatientNotFound}
        />
        <PrivateRoute path="/patients" exact component={Patients} />
        <PrivateRoute path="/patients/new" exact component={NewPatient} />
        <PrivateRoute
          path="/patients/new/existent"
          exact
          component={PatientExistent}
        />
        <PrivateRoute
          path="/patients/new/personalinfo"
          exact
          component={PatientPersonalInfo}
        />
        <PrivateRoute path="/patients/:id" exact component={PatientsById} />
        <PrivateRoute
          path="/patients/:id/attendances/new"
          component={AttendancesNewById}
        />
        <PrivateRoute path="/users/new" component={NewUser} />
        <Route path="/teste" component={Teste} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

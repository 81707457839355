import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import ContentContainer from "../../../components/ContentContainer";
import api from "../../../services/api";
import { getHospitalId } from "../../../services/auth";
import { formStyles, notifyError } from "../../../utils";

export default function NewUser() {
  const formClasses = formStyles();
  const [user, setUser] = useState({});
  const hospitalId = getHospitalId();

  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      username: user.username,
      password: user.password,
      email: user.email,
      document: user.document,
    };
    try {
      await api.post(`institutions/${hospitalId}/dependents`, data);
    } catch (error) {
      notifyError(error);
    }
  }

  function handleEditUser(field, value) {
    let newUser = { ...user };
    newUser[field] = value;
    setUser(newUser);
  }

  return (
    <ContentContainer>
      <h1 className="text-center">Criar novo usuário</h1>
      <form onSubmit={handleSubmit}>
        <p>
          <TextField
            required
            className={formClasses.fields}
            label="Usuário"
            value={user.username}
            onChange={(e) => handleEditUser("username", e.target.value)}
          />
        </p>
        <p>
          <TextField
            required
            className={formClasses.fields}
            type="password"
            label="Senha"
            value={user.password}
            onChange={(e) => handleEditUser("password", e.target.value)}
          />
        </p>
        <p>
          <TextField
            required
            className={formClasses.fields}
            type="email"
            label="Email"
            value={user.email}
            onChange={(e) => handleEditUser("email", e.target.value)}
          />
        </p>
        <p>
          <TextField
            required
            className={formClasses.fields}
            label="CPF"
            value={user.document}
            onChange={(e) => handleEditUser("document", e.target.value)}
          />
        </p>
        <button
          className="flex-row margin-top-medium button-tertiary"
          type="submit"
        >
          Submeter
        </button>
      </form>
    </ContentContainer>
  );
}

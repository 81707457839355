import React, { useEffect, useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "./styles.css";

import marker from "../../../assets/marker.svg";

import AnalyteLine from "../../AnalyteLine";
import { notifyError } from "../../../utils";
import api from "../../../services/api";

export default function CovidCbcAccordion({ attendance, prediction }) {
  const [model, setModel] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get(`/models/${prediction.model_id}`);
        setModel(response.data);
      } catch (error) {
        notifyError(error);
      }
    }
    fetchData();
  }, [prediction, attendance]);

  // const cbcItems = [
  //   {
  //     name: "Leucograma",
  //     analytes: [
  //       {
  //         name: "Leucócitos",
  //         unit: "/mm³",
  //         value: attendance.detailsleucocitos,
  //         nodes: [
  //           { out: true, percentage: 30, label: "<4500" },
  //           { out: false, percentage: 40 },
  //           { out: true, percentage: 30, label: ">11000" },
  //         ],
  //       },
  //       {
  //         name: "Neutrófilos",
  //         unit: "/mm³",
  //         value: cbc.neutrofilos,
  //         nodes: [
  //           { out: true, percentage: 30, label: "<1600" },
  //           { out: false, percentage: 40 },
  //           { out: true, percentage: 30, label: ">8000" },
  //         ],
  //       },
  //       {
  //         name: "Eusinófilos",
  //         unit: "/mm³",
  //         value: cbc.eosinofilos,
  //         nodes: [
  //           { out: true, percentage: 30, label: "<100" },
  //           { out: false, percentage: 40 },
  //           { out: true, percentage: 30, label: ">400" },
  //         ],
  //       },
  //       {
  //         name: "Basófilos",
  //         unit: "/mm³",
  //         value: cbc.basofilos,
  //         nodes: [
  //           { out: false, percentage: 50 },
  //           { out: true, percentage: 50, label: ">100" },
  //         ],
  //       },
  //       {
  //         name: "Linfócitos",
  //         unit: "/mm³",
  //         value: cbc.linfocitos,
  //         nodes: [
  //           { out: true, percentage: 30, label: "<900" },
  //           { out: false, percentage: 40 },
  //           { out: true, percentage: 30, label: ">4400" },
  //         ],
  //       },
  //       {
  //         name: "Monócitos",
  //         unit: "/mm³",
  //         value: cbc.monocitos,
  //         nodes: [
  //           { out: true, percentage: 30, label: "<200" },
  //           { out: false, percentage: 40 },
  //           { out: true, percentage: 30, label: ">800" },
  //         ],
  //       },
  //       {
  //         name: "Plaquetas",
  //         unit: "/mm³",
  //         value: cbc.plaquetas,
  //         nodes: [
  //           { out: true, percentage: 30, label: "<150000" },
  //           { out: false, percentage: 40 },
  //           { out: true, percentage: 30, label: ">400000" },
  //         ],
  //       },
  //       {
  //         name: "NLR",
  //         unit: "",
  //         value: cbc.nlr,
  //         nodes: [
  //           { out: true, percentage: 30 },
  //           { out: false, percentage: 40 },
  //           { out: true, percentage: 30 },
  //         ],
  //       },
  //       {
  //         name: "LMR",
  //         unit: "",
  //         value: cbc.lmr,
  //         nodes: [
  //           { out: true, percentage: 30 },
  //           { out: false, percentage: 40 },
  //           { out: true, percentage: 30 },
  //         ],
  //       },
  //       {
  //         name: "PLR",
  //         unit: "",
  //         value: cbc.plr,
  //         nodes: [
  //           { out: true, percentage: 30 },
  //           { out: false, percentage: 40 },
  //           { out: true, percentage: 30 },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     name: "Eritrograma",
  //     analytes: [
  //       {
  //         name: "VCM",
  //         unit: "/U³",
  //         value: cbc.plr,
  //         nodes: [
  //           { out: true, percentage: 30, label: "<80" },
  //           { out: false, percentage: 40 },
  //           { out: true, percentage: 30, label: ">100" },
  //         ],
  //       },
  //       {
  //         name: "HCM",
  //         unit: "/pg",
  //         value: cbc.plr,
  //         nodes: [
  //           { out: true, percentage: 30, label: "<26" },
  //           { out: false, percentage: 40 },
  //           { out: true, percentage: 30, label: ">34" },
  //         ],
  //       },
  //     ],
  //   },
  // ];

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className="flex-row flex-axis-center flex-space-between width-100p">
          <span className="size-larger">{model.name_ptbr}</span>
          <button className="button2-warning-small">
            {prediction.class_name_ptbr === "1"
              ? "Provável COVID"
              : prediction.class_name_ptbr === "2"
              ? "Improvável COVID"
              : prediction.class_name_ptbr}
          </button>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="width-100p">
          <div className="flex-wrap margin-bottom-small">
            <div className="flex-row flex-axis-center margin-xxsmall">
              <div className="legend-box background-warning3"></div>
              <span className="size-smaller margin-left-xsmall">
                Fora da faixa de referência
              </span>
            </div>
            <div className="flex-row flex-axis-center margin-xxsmall">
              <div className="legend-box background-positive"></div>
              <span className="size-smaller margin-left-xsmall">
                Dentro da faixa de referência
              </span>
            </div>
            <div className="flex-row flex-axis-center margin-xxsmall">
              <img src={marker} alt="" height="24px" />
              <span className="size-smaller margin-left-xsmall">
                Valor do paciente
              </span>
            </div>
            <div className="flex-row flex-axis-center margin-xxsmall">
              <div style={{ borderLeft: "3px dotted black", height: "24px" }} />
              <span className="size-smaller margin-left-xsmall">
                Limite para mudança de resultado
              </span>
            </div>
            <div className="flex-row flex-axis-center margin-xxsmall">
              <div className="legend-box background-warning2"></div>
              <span className="size-smaller margin-left-xsmall">
                Importância
              </span>
            </div>
          </div>

          <div className="flex-wrap flex-space-between">
            <div className="flex-column max-width">
              {prediction.analytes_interpretations.map((ai) => (
                <AnalyteLine key={ai.id} ai={ai} />
              ))}
            </div>
            <div className="flex-column max-width-xxxlarge">
              {/* <div className="background-quinary margin-bottom-small padding-small">
                <h1 className="text-center size-larger">
                  Qual análise quer visualizar?
                </h1>
                <p>Radio box</p>
              </div> */}
              <div className="background-quinary margin-bottom-small padding-small">
                <h1 className="text-center size-larger">Interpretação</h1>
                <p>{prediction.interpretation_text}</p>
              </div>
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

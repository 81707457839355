import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function CovidSymptomsAccordion({ prediction }) {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className="flex-row flex-axis-center flex-space-between width-100p">
          <span className="size-larger">COVID-19 por sintomas</span>
          <button className="button2-warning-small">PROVAVEL COVID</button>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="flex-wrap flex-space-between width-100p">
          <div className="flex-column max-width-xxxxlarge">
            <p>
              Probabilidade de ser COVID-19:
              <span className="color-warning"> 67%</span>
            </p>
            <p>Sintomas que mais contribuíram para o diagnóstico:</p>
            <div className="flex-wrap margin-bottom-small">
              <span className="button2-warning-small margin-xxsmall">
                Distúrbios olfativos
              </span>
              <span className="button2-warning-small margin-xxsmall">
                Febre
              </span>
              <span className="button2-warning2-small margin-xxsmall">
                Coriza
              </span>
              <span className="button2-warning2-small margin-xxsmall">
                Tosse
              </span>
              <span className="button2-warning2-small margin-xxsmall">
                Dispnéia
              </span>
              <span className="button2-warning2-small margin-xxsmall">
                Sintomas há 5 dias
              </span>
            </div>
            <p>
              Probabilidade de agravamento:
              <span className="color-warning"> 49%</span>
            </p>
            <p>Contribuiu para o prognóstico:</p>
            <div className="flex-wrap margin-bottom-small">
              <span className="button2-warning-small margin-xxsmall">
                Doença respiratória crônica
              </span>
              <span className="button2-warning-small margin-xxsmall">
                Febre
              </span>
              <span className="button2-warning2-small margin-xxsmall">
                Sintomas há 5 dias
              </span>
            </div>
          </div>
          <div className="flex-column max-width-xxxxlarge padding-small background-quinary">
            <h1 className="text-center size-larger">Interpretação</h1>
            {prediction.interpretation_text}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

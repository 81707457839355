import React from "react";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import "./global.css";

import Routes from "./routes";

import HospitalProvider from "./contexts/HospitalContext";

function App() {
  return (
    <React.Fragment>
      <ReactNotification />
      <HospitalProvider>
        <Routes />
      </HospitalProvider>
    </React.Fragment>
  );
}

export default App;

import React from "react";

import { Link } from "react-router-dom";
import ContentContainer from "../../components/ContentContainer";
import ListTable from "../../components/ListTable";
import { getHospitalId } from "../../services/auth";

export default function Patients() {
  const hospitalId = getHospitalId();

  return (
    <ContentContainer>
      <h1 className="text-center">Pacientes</h1>
      <Link to="/patients/new">
        <button className="button-tertiary margin-vertical-medium">
          Novo paciente
        </button>
      </Link>
      <ListTable
        baseUrl={`/institutions/${hospitalId}`}
        itemsUrl="/patients"
        fields={[{ name: "Paciente", path: "name" }]}
      />
    </ContentContainer>
  );
}

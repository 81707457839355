import React from "react";
import AnalyteBar from "../AnalyteBar";
import AnalyteDesc from "../AnalyteDesc";

import "./styles.css";

export default function AnalyteLine({ ai = {} }) {
  return (
    <div className="flex-wrap">
      <AnalyteDesc analyte={ai.measurement} />
      <div className="margin-xxsmall">
        <AnalyteBar ai={ai} />
      </div>
      <span className="width-analyte-importance background-warning2 radius text-center margin-xxsmall">
        {Math.round(ai.prediction_importance * 100)} %
      </span>
    </div>
  );
}

import React from "react";
import { NavLink } from "react-router-dom";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import PersonIcon from "@material-ui/icons/Person";
// import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import Collapse from "@material-ui/core/Collapse";
// import ExpandLess from "@material-ui/icons/ExpandLess";
// import ExpandMore from "@material-ui/icons/ExpandMore";
// import ListItemText from "@material-ui/core/ListItemText";

import "./styles.css";

export default function SideMenu({ marginTop = false, boxRight = false }) {
  // const [openAdmin, setOpenAdmin] = useState(true);
  return (
    <div
      className={`${
        marginTop && "height-header-margin"
      } background-secondary height-except-header overflow-y-auto box-top ${
        boxRight && "box-right"
      } width-side-menu`}
    >
      <div className="flex-column margin-top-medium">
        <NavLink
          to="/attendances"
          activeClassName="active-side-menu icon-tertiary"
          className="flex-row flex-axis-center lighter background-hover padding-vertical-small padding-sides-medium"
        >
          <LocalHospitalIcon />
          &nbsp;&nbsp;Atendimentos
        </NavLink>
        <NavLink
          to="/patients"
          activeClassName="active-side-menu icon-tertiary"
          className="flex-row flex-axis-center lighter background-hover padding-vertical-small padding-sides-medium"
        >
          <PersonIcon />
          &nbsp;&nbsp;Pacientes
        </NavLink>
        {/* <ListItem button onClick={() => setOpenAdmin(!openAdmin)}>
          <ListItemText primary="Administração" />
          {openAdmin ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openAdmin} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <NavLink
              to="/users/new"
              activeClassName="active-side-menu icon-tertiary"
              className="flex-row flex-axis-center lighter background-hover padding-vertical-small padding-sides-medium"
            >
              <SupervisorAccountIcon />
              &nbsp;&nbsp;Criar usuário
            </NavLink>
          </List>
        </Collapse> */}
      </div>
    </div>
  );
}

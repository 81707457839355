import React, { useState } from "react";
import { TextField } from "@material-ui/core";
// import { useHistory } from "react-router";
import Modal from "@material-ui/core/Modal";
import api from "../../../services/api";

import ContentContainer from "../../../components/ContentContainer";
import { notifyError } from "../../../utils";
import Badge from "../../../components/Patients/Badge";
import { Link } from "react-router-dom";

export default function NewAttendance(props) {
  // const history = useHistory();
  const [searchString, setSearchString] = useState(
    props.location.state && props.location.state.searchString
      ? props.location.state.searchString
      : ""
  );
  const [patients, setPatients] = useState([]);
  const [openPatientSelection, setOpenPatientSelection] = useState(false);

  async function handleSubmitSearch(e) {
    e.preventDefault();

    try {
      const response = await api.get(`/users?q=${searchString}`);
      setPatients(response.data);
      setOpenPatientSelection(true);
      // if (patients.length >= 1) {
      //   history.push(`/patients/${patients[0].id}`);
      // } else {
      //   history.push({
      //     pathname: "/attendances/new/notfound",
      //     state: { searchString: searchString },
      //   });
      // }
    } catch (error) {
      notifyError(error);
    }
  }

  return (
    <React.Fragment>
      <Modal
        open={openPatientSelection}
        onClose={() => setOpenPatientSelection(false)}
      >
        <div className="modal padding-medium">
          <h1 className="size-large">Resultado da busca</h1>
          {patients.length > 0 ? (
            <React.Fragment>
              <p>Escolha o paciente para continuar</p>
              {patients.map((patient) => (
                <Link key={patient.id} to={`/patients/${patient.id}`}>
                  <Badge
                    patient={patient}
                    canHideData={false}
                    linkActive={false}
                  />
                </Link>
              ))}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <p>A busca não retornou nenhum resultado.</p>
              <Link className="button-tertiary" to="/patients/new">
                Registrar novo paciente
              </Link>
              <button
                className="button-tertiary margin-left-xsmall"
                onClick={() => setOpenPatientSelection(false)}
              >
                Buscar novamente
              </button>
            </React.Fragment>
          )}
        </div>
      </Modal>

      <ContentContainer>
        <h1 className="text-center">Novo atendimento</h1>
        <p>Insira os dados do paciente para iniciar</p>
        <form autoComplete="off" onSubmit={handleSubmitSearch}>
          <div className="flex-wrap flex-axis-center flex-start margin-bottom-medium">
            <TextField
              required
              autoFocus
              label="CPF ou nome"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
              inputProps={{
                maxLength: 30,
                minLength: 1,
              }}
            />
          </div>
          <button className="button-tertiary" type="submit">
            Continuar
          </button>
        </form>
      </ContentContainer>
    </React.Fragment>
  );
}

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";

import api from "../../services/api";
import { setUserInfo } from "../../services/auth";
import { userIsAuthenticated } from "../../services/auth";
import { notifyError } from "../../utils";

import "./styles.css";

export default function Index() {
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (userIsAuthenticated()) {
      history.push("/hospitalselection");
    }
  }, [history]);

  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      username: username,
      password: password,
    };

    try {
      const response = await api.post("auth/login", data);
      setUserInfo(response.data);
      history.push("/hospitalselection");
    } catch (error) {
      notifyError(error);
    }
  }

  return (
    <div className="flex-column flex-axis-center flex-crossaxis-center min-height-100vh">
      <h1 className="color-tertiary bold">LabLift App</h1>
      <p className="size-small">Preencha seus dados</p>
      <form
        className="padding-medium flex-column content-box"
        onSubmit={handleSubmit}
      >
        <TextField
          required
          label="Usuário"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          required
          className="margin-top-small"
          type="password"
          label="Senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="margin-top-medium button-tertiary" type="submit">
          Login
        </button>
      </form>
      <p className="margin-top-small size-small">
        Esqueceu sua senha?{" "}
        <Link className="underline color-tertiary" to="/recover">
          Recupere agora!
        </Link>
      </p>
    </div>
  );
}

import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import ContentContainer from "../../../../components/ContentContainer";

export default function PatientExistent(props) {
  const history = useHistory();
  const patient = props.location.state && props.location.state.patient;
  if (!patient) {
    history.push("/patients/new");
  }

  return (
    <ContentContainer>
      <h1 className="text-center">Novo paciente</h1>
      <p>
        Paciente com CPF <span className="bold">{patient.cpf}</span> já existe
      </p>
      <Link to={{ pathname: "/patients/new", state: { cpf: patient.cpf } }}>
        <button className="button-tertiary">Buscar novamente</button>
      </Link>
      <Link
        to={{ pathname: `/patients/${patient.id}` }}
        className="margin-left-xsmall"
      >
        <button className="button-tertiary">Acessar paciente</button>
      </Link>
    </ContentContainer>
  );
}

import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import { useHistory } from "react-router";
import api from "../../../services/api";

import ContentContainer from "../../../components/ContentContainer";
import { notifyError } from "../../../utils";

export default function PatientPersonalInfo(props) {
  const history = useHistory();
  const [cpf, setCpf] = useState(
    props.location.state && props.location.state.cpf
      ? props.location.state.cpf
      : ""
  );

  async function handleSubmitCpf(e) {
    e.preventDefault();
    try {
      const response = await api.get(`/users?document=${cpf}`);
      const patients = response.data;
      if (patients.length === 1) {
        history.push({
          pathname: `/patients/new/existent`,
          state: { patient: patients[0] },
        });
      } else {
        history.push({
          pathname: "/patients/new/personalinfo",
          state: { cpf: cpf },
        });
      }
    } catch (error) {
      notifyError(error);
    }
  }

  return (
    <ContentContainer>
      <h1 className="text-center">Novo paciente</h1>
      <form autoComplete="off" onSubmit={handleSubmitCpf}>
        <div className="flex-wrap flex-axis-center flex-start margin-bottom-medium">
          <TextField
            required
            label="CPF"
            value={cpf}
            onChange={(e) => setCpf(e.target.value)}
            inputProps={{
              maxLength: 11,
              minLength: 11,
            }}
          />
        </div>
        <button className="button-tertiary" type="submit">
          Continuar
        </button>
      </form>
    </ContentContainer>
  );
}

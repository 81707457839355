import axios from "axios";
import { getUserInfo } from "./auth";

export const apiBaseUrl = process.env.REACT_APP_URL
  ? process.env.REACT_APP_URL
  : "http://localhost:5000";
export const frontendUrl = process.env.REACT_APP_FRONTENDURL
  ? process.env.REACT_APP_FRONTENDURL
  : "http://localhost:3000";

const api = axios.create({
  baseURL: `${apiBaseUrl}/api/`,
});

api.interceptors.request.use(async (config) => {
  const access_token = getUserInfo().access_token;
  if (access_token) {
    config.headers.Authorization = `Bearer ${access_token}`;
  }
  return config;
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (!axios.isCancel(error)) {
      if (error.response.status === 401) {
        const token = getUserInfo().access_token;
        if (token) {
          logout(false);
          window.location.href = "/";
          return;
        }
      }
    }

    return Promise.reject(error);
  }
);

export function logout(redirect = true) {
  localStorage.clear();
  if (redirect) {
    window.location.href = "/";
  }
}

export default api;

const patients = [
  {
    id: 1,
    name: "Rafael Bizao",
    cpf: "11111111111",
  },
  {
    id: 1,
    name: "Jairo",
    cpf: "22222222222",
  },
  {
    id: 1,
    name: "Tiago",
    cpf: "33333333333",
  },
];

export function getPatientByCpf(cpf) {
  return patients.filter((patient) => patient.cpf === cpf)[0];
}

export function getPatients() {
  return patients;
}

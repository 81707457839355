import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useHistory } from "react-router";
import ContentContainer from "../../../../components/ContentContainer";

import api from "../../../../services/api";
import { notifyError, formStyles } from "../../../../utils";
import { getHospitalId } from "../../../../services/auth";

export default function NewPatient(props) {
  const history = useHistory();
  const cpf = props.location.state && props.location.state.cpf;
  if (!cpf) {
    history.push("/patients/new");
  }
  const formClasses = formStyles();
  const [patient, setPatient] = useState({
    document: cpf,
    document_type: "cpf",
  });
  const hospitalId = getHospitalId();

  function handleEditPatient(field, value) {
    let newPatient = { ...patient };
    newPatient[field] = value;
    setPatient(newPatient);
  }

  async function handleSubmitPatient(e) {
    e.preventDefault();
    try {
      const response = await api.post(
        `/institutions/${hospitalId}/patients`,
        patient
      );

      history.push(`/patients/${response.data.id}`);
    } catch (error) {
      notifyError(error);
    }
  }

  return (
    <ContentContainer>
      <h1 className="text-center">Novo paciente</h1>
      <form autoComplete="off" onSubmit={handleSubmitPatient}>
        <div className="flex-wrap flex-axis-center flex-start margin-bottom-medium">
          <TextField
            className={formClasses.fields}
            required
            label="Nome"
            value={patient.first_name || ""}
            onChange={(e) => handleEditPatient("first_name", e.target.value)}
          />
          <TextField
            className={formClasses.fields}
            required
            label="Sobrenome"
            value={patient.last_name || ""}
            onChange={(e) => handleEditPatient("last_name", e.target.value)}
          />
          <FormControl required className={formClasses.fields}>
            <InputLabel id="select-gender">Genero</InputLabel>
            <Select
              labelId="select-gender"
              value={patient.gender || ""}
              onChange={(e) => handleEditPatient("gender", e.target.value)}
            >
              <MenuItem value={"undefined"}>Nao informado</MenuItem>
              <MenuItem value={"male"}>Masculino</MenuItem>
              <MenuItem value={"female"}>Feminino</MenuItem>
            </Select>
          </FormControl>
          <TextField
            className={formClasses.fields}
            required
            type="date"
            label="Nascimento"
            InputLabelProps={{
              shrink: true,
            }}
            value={patient.date_of_birth || ""}
            onChange={(e) => handleEditPatient("date_of_birth", e.target.value)}
          />
        </div>
        <button className="button-tertiary" type="submit">
          Continuar
        </button>
      </form>
    </ContentContainer>
  );
}

import React from "react";

export default function Teste() {
  return (
    <div>
      <div style={{ backgroundColor: "black", width: "200px", height: "30px" }}>
        <div
          className="flex-row"
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          <div
            style={{ backgroundColor: "green", width: "30%", height: "100%" }}
          ></div>
          <div
            style={{ backgroundColor: "red", width: "30%", height: "100%" }}
          ></div>
          <div
            style={{ height: "100%", width: "2px", backgroundColor: "pink" }}
          ></div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import Header from "../Header";
import SideMenu from "../SideMenu";

export default function ContentContainer(props) {
  return (
    <React.Fragment>
      <Header />
      <div className="flex-row">
        <div id="small-hide">
          <SideMenu boxRight={true} />
        </div>
        <div className="height-except-header overflow-y-auto width-100p">
          <div className="padding-sides-xsmall padding-vertical-large margin-small">
            {props.children}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
